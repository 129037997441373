@charset "utf-8";
@use "sass:math";

/* ------------------------------
　　ベース
------------------------------ */
* {
}

body {
	color: #333;
	font-family: "Noto Sans JP", sans-serif;
	background: #fff;
	font-size: 14px;
	font-style: normal;
}

/* リンクの設定 */
a {
	color: #006dd9;
	text-decoration: none;
	border: none;
}

a:visited {
	color: #7f5ad7;
	text-decoration: none;
}

a:active {
	color: #006dd9;
	text-decoration: none;
}

a:hover {
	color: #006dd9;
	text-decoration: underline;
}

*:focus {
	outline: none;
}

p {
	text-align: justify;
	text-justify: inter-ideograph;
	line-height: 1.6;
}

img {
	width: 100%;
	height: auto;
}
ul {
	list-style: none;
}

@media (min-width: 768px) {
	body {
		font-size: 15px;
	}
}

/* ------------------------------
　　色
------------------------------ */
$primary-color: #0075c2;
$text-color-base: #0d0100;

/* ------------------------------
　　共通
------------------------------ */
.h2_titlebox {
	margin: 0 auto;
	text-align: center;
	z-index: 10;
	position: relative;
	// h2 {
	// 	position: relative;
	// 	color: #0075c2;
	// 	font-size: 38px;
	// }
	// p {
	// 	text-align: right;
	// 	font-size: 14px;
	// 	color: #404040;
	// }
	h2 {
		// font-weight: bold;
		padding-bottom: 0.2em;
		margin-bottom: 16px;
		text-align: center;
		position: relative;
		color: #333;
		font-size: 38px;

		&::after {
			content: "";
			background-color: #0075c2;
			width: 1.2em;
			height: 5px;
			position: absolute;
			bottom: 0;
			left: 50%;
			border-radius: 3px;
			transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
		}
	}
}

.text-right {
	text-align: right;
}

/*================================================*/
/* ヘッダー */
/*================================================*/
header {
	position: relative;
	width: 100%;
	min-height: auto;
	max-height: auto;
	height: auto;
	border: 15px solid $primary-color;
	overflow: visible;

	.pc_inner {
		position: relative;
		margin: 0 auto;
		max-width: 1920px;
		width: 100%;
		height: calc(100% - 15px);
	}
	// .student01 {
	//   display: none;
	// }
	// .student02 {
	//   display: none;
	// }
}

.header_img_title {
	margin: 24px 5% 0 5%;
	.subtitle {
		padding-bottom: 8px;
		img {
			width: 50%;
			max-width: 480px;
		}
	}
	h1 {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		img {
			// max-width: 1430px;
		}
		.title01 {
			width: 50%;
			max-width: 360px;
			// width: 100%;
			padding-bottom: 8px;
		}
		.title02 {
			width: 100%;
		}
	}
}

.header_bg {
	position: absolute;
	bottom: 0;
	z-index: -1;
	img {
		vertical-align: bottom;
		width: 100%;
	}
}

.header_img_time {
	position: relative;
	bottom: 0;
	right: 0;
	width: 100%;
	.inner {
		margin: 0 auto 24px;
		max-width: 320px;
		width: 92%;
		img {
			text-align: right;
		}
	}
	.textbox {
		margin: 16px 0 0 auto;
		background-color: $primary-color;
    border: 2px solid #fff;
		width: 100%;
		p {
			padding: 16px;
			color: #fff;
			font-size: 14px;
		}
	}
}
.header_img_student {
	width: 100%;

	.inner {
		position: relative;
		margin: 0 auto;
		text-align: center;
		padding: 24px;
		img {
			width: 100%;
		}
	}
}

.header_img_day {
	position: relative;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	.inner {
		margin: 10px auto 0;
		max-width: 360px;
		width: 90%;
	}
}

.yellobox {
	display: block;
	background-color: #ffd900;
	padding: 44% 0;
}

@media (min-width: 599px) {
	header {
		min-height: 768px;
		max-height: 900px;
		//height: 94vh;
		overflow: hidden;
    height: 900px;

	}

	.header_img_student {
		.inner {
			top: 50%;
			img {
				max-width: 520px;
			}
		}
	}

	.header_img_day {
		position: absolute;
		bottom: 24px;
		left: 5%;
		width: calc(100% - 5%);
		.inner {
			margin: 0 auto 0 0;
			max-width: 500px;
			width: 44%;
		}
	}
	.header_img_time {
		position: absolute;
		bottom: 24px;
		right: 5%;
		width: calc(100% - 5%);
		.inner {
			margin: 0 0 0 auto;
			max-width: 318px;
			width: 36%;
		}
	}
	.yellobox {
		padding: 23% 0;
	}
}

@media (min-width: 769px) {
	header {
		max-height: 640px;
		max-height: 900px;
		.pc_inner {
		}

		.main_bg {
		}
		.title_bg {
			h1 {
			}
		}
		.header_img_student {
			.inner {
				position: absolute;
				top: 60%;
				left: 70%;
				transform: translate(-50%, -50%);
				-webkit-transform: translate(-50%, -50%);
				// width: auto;
				width: 100%;
				padding: 0;
				img {
					max-width: 600px;
				}
			}
		}
	}
	.header_img_title {
		h1 {
			.title01 {
				padding-bottom: 16px;
			}
		}
	}

	.header_img_day {
		.inner {
		}
	}
	.header_img_time {
		.inner {
		}
	}
	.yellobox {
		padding: 10% 0;
	}
}

@media (min-width: 900px) {
	header {
    //height: 94vh;
    height: 900px;
		.pc_inner {
		}

		.main_bg {
		}
		.title_bg {
			h1 {
			}
		}
		.header_img_student {
			.inner {
				top: 55%;
        left: 60%;

				img {
					max-width: 700px;
					// width: 80%;
				}
			}
		}
	}

	.header_img_title {
		// margin: 24px 5% 0 5%;
		.subtitle {
			padding-bottom: 16px;
			// img {
			// 	width: 50%;
			// 	max-width: 480px;
			// }
		}
		h1 {
			.title01 {
				width: 34%;
				padding-bottom: 0;
				padding-right: 8px;
			}
			.title02 {
				width: 66%;
			}
		}
	}

	.header_img_time {
		.inner {
		}
	}
	.yellobox {
		padding: 2% 0;
	}
}

@media (min-width: 1367px) {
	header {
		min-height: 1020px;
		max-height: 1100px;
		.pc_inner {
		}

		.main_bg {
		}
		.title_bg {
			h1 {
				img {
					width: 95%;
				}
			}
		}
		.header_img_student {
			.inner {
				img {
					// width: 100%;
					max-width: 860px;
				}
			}
		}
	}

	.header_img_day {
		.inner {
			max-width: 500px;
		}
	}
	.header_img_time {
		.inner {
			max-width: 380px;
		}
	}
	.yellobox {
		display: none;
	}
}

@media (min-width: 1921px) {
	header {
		max-height: 92vh;
		.pc_inner {
		}

		.main_bg {
		}
		.title_bg {
			h1 {
			}
		}
		.header_img_student {
			.inner {
				top: 64%;
				img {
					// width: 100%;
					max-width: 1000px;
				}
			}
		}
	}
	.header_img_day {
		.inner {
			max-width: 620px;
		}
	}
	.header_img_time {
		.inner {
			max-width: 520px;
		}
	}
}
/*================================================*/
/* コロナ対策 */
/*================================================*/

// section.corona_wrap {
// 	margin: 80px auto;

// 	.corona_box {
// 		margin: 0 auto;
// 		max-width: 980px;
// 		width: 94%;

// 		.corona_inner {
// 			border: 1px solid #0075c2;
// 			background: #fff;
// 			border-top: none;
// 			box-sizing: border-box;

// 			.titlebox {
// 				padding: 18px 20px;
// 				background: #0075c2;

// 				h2 {
// 					color: #fff;
// 					text-align: left;
// 					font-size: 18px;
// 					line-height: 1.6;
// 				}
// 			}

// 			.textbox {
// 				margin: 0 auto;
// 				width: 94%;
// 				padding: 24px 0;

// 				p {
// 					line-height: 1.6;
// 					text-align: justify;

// 					padding-left: 1em;
// 					text-indent: -1em;

// 					&:before {
// 						content: "・";
// 					}
// 				}
// 			}

// 			&:nth-of-type(1) {
// 				border: none;
// 			}
// 		}
// 	}
// }

// .layout_box {
// 	margin: 48px auto;
// 	text-align: center;

// 	a {
// 		text-decoration: underline;
// 		text-decoration-color: #fff;
// 		&:hover {
// 			opacity: 0.8;
// 			text-decoration: none;
// 			color: #fff;
// 		}

// 		i {
// 			position: absolute;
// 			top: 50%;
// 			right: 20px;
// 			transform: translateY(-50%);
// 			-webkit-transform: translateY(-50%);
// 			margin: auto;
// 		}

// 		.btn {
// 			position: relative;
// 			margin: 0 auto;
// 			text-align: center;
// 			padding: 32px 10px;
// 			border-radius: 8px;
// 			color: #fff;
// 			background-color: #e3540b;
// 			font-size: 20px;
// 			font-weight: bold;
// 		}

// 		.btn:hover > i {
// 			transform: translateX(4px) translateY(-13px);
// 			transition: all 300ms 0s ease;
// 		}
// 	}
// }

// @media (min-width: 1024px) {
// 	section.corona_wrap {
// 		.corona_box {
// 			width: 100%;

// 			.corona_inner {
// 				.titlebox {
// 					padding: 24px 0;

// 					h2 {
// 						font-size: 22px;
// 					}
// 				}

// 				.textbox {
// 					padding: 38px 0;
// 				}
// 			}
// 		}
// 	}
// }

@media (min-width: 550px) {
	// section.corona_wrap {
	// 	.corona_box {
	// 		.corona_inner {
	// 			.titlebox {
	// 				h2 {
	// 					text-align: center;
	// 				}
	// 			}
	// 			.textbox {
	// 			}
	// 		}
	// 	}
	// }

	.layout_box {
		// margin: 48px auto;
		// text-align: center;

		a {
			// &:hover {
			//   opacity: 0.8;
			//   text-decoration: none;
			//   color: #fff;
			// }

			i {
				// position: absolute;
				// top: 50%;
				// right: 20px;
				// transform: translateY(-50%);
				// -webkit-transform: translateY(-50%);
				// margin: auto;
			}

			.btn {
				// position: relative;
				// margin: 0 auto;
				// text-align: center;
				// padding: 32px 10px;
				// border-radius: 8px;
				// color: #fff;
				// background-color: #e3540b;
				// font-size: 26px;
				// font-weight: bold;
			}

			// .btn:hover >  i {
			//   transform: translateX(4px) translateY(-8px);
			//   transition: all 300ms 0s ease;
			// }
		}
	}
}

/*================================================*/

/* 参加企業 */

/*================================================*/

.corporate_wrap {
	margin: 50px auto;
	padding: 30px 0 0;
	background-repeat: repeat;

	.corporate_inner {
		margin: 0 auto;
		max-width: 980px;
		width: 94%;

		.bigbox {
			width: 100%;
		}
	}
}

.cate_box {
	margin: 50px auto;

	.titlebox {
		padding: 23px 0;
		border-radius: 14px 14px 0px 0px;
		// border-radius: 20px;
		background: #0075c2;

		h3 {
			padding-left: 32px;
			color: #fff;
			font-size: 19px;
			text-align: left;
		}
	}
}

.industry-box {
	width: 100%;
	// background: #fff;
	// background: #F2F8FC;
	border-radius: 0px 0px 8px 8px;
	border: 1px solid #0075c2;
	// box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  h4{
    border-top: 1px solid #0075c2;
    color: #0075c2;
    font-size: 18px;

    &:first-child{
      border-top: none;
    }
  }

	.industry-inner {
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		// align-items: stretch;
		width: 90%;
		padding: 10px 0;
	}

  h4.industry-inner {
    padding-top: 30px;
  }
}

.industry-item {
	width: 100%;
	padding: 0;
	vertical-align: middle;

	p {
		display: inline-block;
		vertical-align: middle;
		width: 77%;
	}

	.linkimg {
		width: 8%;
		padding-right: 2%;
		display: inline-block;
		vertical-align: middle;

		img,
		svg {
			// width: 22px;
			width: 20px;
		}
		svg {
			fill: #ea6c00;
		}
	}
	div.linkimg {
		svg {
			fill: #d8d8d8;
		}
	}

	.pdfimg {
		display: inline-block;
		vertical-align: middle;

		img,
		svg {
			width: 18px;
		}
		svg {
			fill: #ea6c00;
		}
	}

	div.pdfimg {
		svg {
			fill: #d8d8d8;
		}
	}

	a {
		&:hover {
			opacity: 0.7;
		}
	}
}

.karitext {
	margin: 80px auto 100px;
	text-align: center;
	font-size: 1.6rem;
	font-weight: bold;
	// background-color: #fff;
	// border: 1px solid #000;
	padding: 32px 0;
}

.corporate_wrap {
	.corporate_inner {
		.bigbox {
			.cate_box {
				&:first-of-type {
					margin: 20px auto 40px;
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.corporate_wrap {
		margin: 0 auto 0;
		padding: 80px 0;
		// background: #E9F3FA;
		// background-image: url("../images/bg.svg");
		// background-repeat: repeat;

		.corporate_inner {
			// margin: 0 auto;
			// max-width: 980px;
			// width: 100%;

			.bigbox {
				// width: 100%;
			}
		}
	}

	.cate_box {
		// margin: 50px auto;

		.titlebox {
			// padding: 23px 0;
			// border-radius: 20px 20px 0px 0px;
			// border-radius: 20px;
			// background: #0075C2;

			h3 {
				padding-left: 48px;
				color: #fff;
				font-size: 24px;
				text-align: left;
			}
		}
	}

	.industry-box {
		// width: 100%;
		// background: #fff;
		// border-radius: 0px 0px 20px 20px;

		.industry-inner {
			// margin: 0 auto;
			// display: flex;
			// flex-wrap: wrap;
			// align-items: stretch;
			// width: 90%;
			// padding: 10px 0;
		}
	}

	.industry-item {
		width: 50%;
		padding: 4px 0;
		vertical-align: middle;

		p {
			display: inline-block;
			vertical-align: middle;
			width: 69%;
		}

		a {
			width: 10%;
			// padding-right: 2%;
			// display: inline-block;
			// vertical-align: middle;

			// img {
			//   width: 22px;
			// }

			// &:nth-of-type(1) {
			//   img {
			//     width: 22px;
			//   }
			// }

			// &:nth-of-type(2) {
			//   img {
			//     width: 18px;
			//   }
			// }

			// &:hover {
			//   opacity: 0.7;
			// }
		}
	}
}

@media (min-width: 1024px) {
	.corporate_wrap {
		// margin: 80px auto;
		// padding: 50px 0;
		// background-image: url("../images/bg.svg");
		// background-repeat: repeat;

		.corporate_inner {
			// margin: 0 auto;
			// max-width: 980px;
			width: 92%;

			.bigbox {
				width: 100%;
			}
		}
	}

	.cate_box {
		margin: 50px auto;

		.titlebox {
			padding: 23px 0;
			border-radius: 8px 8px 0px 0px;
			// border-radius: 20px;
			background: #0075c2;

			h3 {
				padding-left: 48px;
				color: #fff;
				font-size: 24px;
				text-align: left;
			}
		}
	}

	.industry-box {
		// width: 100%;
		// background: #fff;
		// border-radius: 0px 0px 20px 20px;

		.industry-inner {
			// margin: 0 auto;
			// display: flex;
			// flex-wrap: wrap;
			// align-items: stretch;
			// width: 90%;
			// padding: 10px 0;
		}
	}
	.industry-item {
		width: 33.3333%;
		// padding: 16px 0;
		// vertical-align: middle;

		a {
			// width: 10%;
			// padding-right: 2%;
			// display: inline-block;
			// vertical-align: middle;

			// img {
			//   width: 22px;
			// }

			// &:nth-of-type(1) {
			//   img {
			//     width: 22px;
			//   }
			// }

			// &:nth-of-type(2) {
			//   img {
			//     width: 18px;
			//   }
			// }

			// &:hover {
			//   opacity: 0.7;
			// }
		}
	}
}

/*=====================
アコーディオンのためのcss
========================*/

/*アコーディオン全体*/
.accordion-area {
	list-style: none;
	margin: 0 auto;

	li {
		margin: 10px 0;
	}
}

/*アコーディオンタイトル*/
.title {
	position: relative;
	// cursor: pointer;
	font-size: 1rem;
	font-weight: normal;
	padding: 3% 3% 3% 50px;
}

/*=====================
プレゼント
========================*/
.present_wrap {
				max-width: 980px;
				width: 92%;
					margin: 80px auto 60px;
}

.presentbox {
	// margin: 80px auto 60px;
	text-align: center;
}
.presentbox-imgbox {
	margin: 0 0 0 auto;
	text-align: right;
	padding: 0 5% 0 0;
	img {
		vertical-align: bottom;
		max-width: 360px;
		width: 50%;
	}
}

.presentbox-itembox {
	display: block;
	flex-flow: row wrap;
	justify-content: space-between;
	margin-top: 40px;
}
.presentbox-itembox-item {
	width: 100%;
	margin-bottom: 16px;
	.presentbox-itembox-item-inner {
		padding: 24px 14px;
		background-color: #fff;
		border: 4px solid #0075c2;
		border-radius: 8px;

		h2,
		p {
			text-align: center;
			color: #0075c2;
			font-weight: bold;
		}

		h2 {
			letter-spacing: 0.06em;
			padding-bottom: 16px;
			font-size: 18px;
		}
		p {
			font-size: 23px;
		}
	}
}

.ribbon {
	display: inline-block;
	position: relative;
	height: 68px;
	line-height: 68px;
	text-align: center;
	padding: 0 50px;
	// margin-bottom: 10px;
	font-size: 22px;
	background: #0075c2;
	color: #fff;
	box-sizing: border-box;
	&:before,
	&:after {
		position: absolute;
		content: "";
		width: 0px;
		height: 0px;
		z-index: 1;
	}
}

.ribbon:before {
	top: 0;
	left: 0;
	border-width: 34px 0px 34px 15px;
	border-color: transparent transparent transparent #fff;
	border-style: solid;
}

.ribbon:after {
	top: 0;
	right: 0;
	border-width: 34px 15px 34px 0px;
	border-color: transparent #fff transparent transparent;
	border-style: solid;
}

@media (min-width: 768px) {
	.presentbox-itembox {
		display: flex;
		// flex-flow: row wrap;
		// justify-content: space-between;
	}


	.ribbon {
		font-size: 28px;
	}

	.presentbox-itembox-item {
		width: calc(33.333% - 16px);
		.presentbox-itembox-item-inner {
			padding: 32px 18px;
			// background-color: #fff;
			// border: 4px solid #0075c2;
			// border-radius: 8px;

			// h3,
			// p {
			// 	text-align: center;
			// 	color: #0075c2;
			// 	font-weight: bold;
			// }

			h3 {
				// letter-spacing: 0.06em;
				// padding-bottom: 16px;
				font-size: 22px;
			}
			p {
				font-size: 30px;
			}
		}
	}
}

// .text-bgcolor {
// 	background: #fff5ba;
// 	padding: 14px;
// }

.text-small {
	// margin-top: 40px;
	padding: 4px 0;
	// text-align: right;
	font-size: 12px;
	color: #0075c2;
}

/*================================================*/

/* ご来場の方へのお願い */

/*================================================*/

.visit_wrap {
	padding: 40px 0;
	background-color: #fff5ba;

	.visit_inner {
		margin: 0 auto;
		max-width: 720px;
		width: 94%;
	}

	.box_cover {
		margin: 30px auto;
		width: 100%;
		display: block;
		align-items: stretch;

		.box {
			width: 100%;
			border: 4px solid $primary-color;
			// border-radius: 20px;
			background-color: #fff;
      position: relative;


			.inner {
				margin: 0 auto;
				padding: 50px 0;
				width: 90%;

        .hedding{
          position: absolute;
          top: 0;
          left: 0;
          font-size: 24px;
          line-height: 1;
          margin-bottom: 20px;
          font-weight: bold;
          color: #fff;
          background-color: #0075c2;
          padding: 10px 12px 10px 8px;
          border: 3px solid ;
          margin-top: -40px;
          margin-left: -10px;
          border-radius: 10px;
        }

        .checkmark{
          padding-left:32px;
          position:relative;
          &:after{
            content:"";
            display:block;
            position:absolute;
          }

          &:after{
            border-left:4px solid #ffd900;
            border-bottom:4px solid #ffd900;
            width:20px;
            height:12px;
            -webkit-transform:rotate(-45deg);
            transform:rotate(-45deg);
            left:5px;
            top:14px;
          }

        }


				h3 {
					padding-bottom: 20px;
					font-size: 22px;
					color: $primary-color;
				}
        h4{
          margin-bottom: 10px;
          font-size: 17px;
        }

        .list{
          font-weight: bold;
          font-size: 15px;
          display: flex;
          flex-wrap: wrap;
          margin-left: -8px;
          color: #006dd9;
          margin-top: 20px;
          margin-bottom: 30px;
          li{
            border: 2px solid #0075c2;
            padding: 8px;
            margin: 8px;
            border-radius: 2em;
          }
        }

				p {
					line-height: 200%;
				}
			}

			&:nth-of-type(1) {
				margin: 0 0 30px 0;
			}

			&:nth-of-type(2) {
				margin: 30px 0 0 0;
			}
		}

    .visit_box_sec{
      border: #006dd9 2px dotted;
    }
	}
}

.visit_title {
	padding: 16px;
	background: #0075c2;

	h2 {
		color: #fff;
		font-size: 22px;
		text-align: center;
	}
}

@media (min-width: 768px) {
	.visit_wrap {
		padding: 80px 0;


		.visit_inner {
			// margin: 0 auto;
			// max-width: 980px;
			// width: 100%;
		}

		.box_cover {
			margin: 50px auto 0;
			// width: 100%;
			//display: flex;
			// align-items: stretch;

			.box {
				//width: calc(50% - 16px);
				// border: 4px solid #E3540B;
				// border-radius: 20px;




				.inner {
					// margin: 0 auto;
					// padding: 50px 0;
					// width: 90%;



          .hedding{
            position: absolute;
            top: 0;
            left: 0;
            font-size: 24px;
            line-height: 1;
            margin-bottom: 20px;
            font-weight: bold;
            color: #fff;
            background-color: #0075c2;
            padding: 10px 12px 10px 8px;
            border: 3px solid ;
            margin-top: -40px;
            margin-left: -30px;
            border-radius: 10px;
          }



					h3 {
						// padding-bottom: 20px;
						// font-size: 22px;
						// color: #E3540B;
					}

					p {
						// line-height: 200%;
					}
				}

				&:nth-of-type(1) {
					// margin: 0 16px 0 0;
				}

				&:nth-of-type(2) {
					// margin: 0 0 0 16px;
				}
			}
		}
	}

	.visit_title {
		// padding: 16px;
		// background: #0075C2;

		h2 {
			// color: #fff;
			font-size: 28px;
			// text-align: center;
		}
	}
}

@media (min-width: 1024px) {
	.visit_wrap {
		// margin: 120px auto;

		.visit_inner {
			// margin: 0 auto;
			// max-width: 980px;
			width: 100%;
		}

		.box_cover {
			// margin: 50px auto;
			// width: 100%;
			//display: flex;
			// align-items: stretch;

			.box {
				// width: calc(50% - 16px);
				// border: 4px solid #E3540B;
				// border-radius: 20px;

				.inner {
					// margin: 0 auto;
					// padding: 50px 0;
					// width: 90%;　
          h2{

          }

					h3 {
						// padding-bottom: 20px;
						font-size: 22px;
						// color: #E3540B;
					}

					p {
						// line-height: 200%;
					}
				}
			}
		}
	}
}

.btn {
	margin: 41px auto 0;
	text-align: center;

	a {
		position: relative;
		margin: 0 auto;
		text-align: center;
		padding: 15px 60px;
		border-radius: 8px;
		color: #fff;
		background-color: #e3540b;
		font-size: 18px;
		font-weight: bold;

		&:hover {
			opacity: 0.8;
			text-decoration: none;
			color: #fff;
		}

		i {
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			margin: auto;
		}
	}

	a:hover > i {
		transform: translateX(4px) translateY(-9px);
		transition: all 300ms 0s ease;
	}
}

.btn_harf_box {
	margin: 30px auto 0;
	display: flex;
}

a.btn_harf_item {
	// display: inline-block;
	width: 50%;
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	text-align: center;

	div {
		position: relative;
		max-width: 180px;
		width: 90%;
		background-color: #e3540b;
		margin: 0 auto;
		padding: 15px 0;
		border-radius: 8px;
		line-height: 1;

		i {
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			margin: auto;
		}

		&:hover > i {
			transform: translateX(4px) translateY(-9px);
			transition: all 300ms 0s ease;
		}
	}

	&:hover {
		opacity: 0.8;
		text-decoration: none;
	}
}

@media (min-width: 769px) {
}

@media (min-width: 1024px) {
	.btn {
		// margin: 50px auto 0;
		// text-align: center;

		a {
			// position: relative;
			// margin: 0 auto;
			// text-align: center;
			padding: 15px 80px;
			// border-radius: 8px;
			// color: #fff;
			// background-color: #0075C2;
			// font-size: 18px;
			// font-weight: bold;

			// &:hover {
			//   opacity: 0.8;
			//   text-decoration: none;
			//   color: #fff;
			// }

			// i {
			//   position: absolute;
			//   top: 50%;
			//   right: 20px;
			//   transform: translateY(-50%);
			//   -webkit-transform: translateY(-50%);
			//   margin: auto;
			// }
		}

		// a:hover>i {
		//   transform: translateX(4px) translateY(-9px);
		//   transition: all 300ms 0s ease;
		// }
	}
}

/*================================================*/

/* 会場 */

/*================================================*/
.venue_wrap {
	margin: 50px auto 0;
	padding: 30px 0 0;
	// background-color: #fff5ba;

	.venue_inner {
		margin: 0 auto;
		max-width: 980px;
		width: 90%;

		.textbox {
			padding: 0 0 50px;

			p {
				text-align: left;
				line-height: 200%;

				&:nth-of-type(1) {
					// padding: 8px 0 0;
					font-size: 20px;
					font-weight: bold;
				}

				&:nth-of-type(2) {
					padding: 0 0 8px;
				}

				&:nth-of-type(3) {
					padding: 8px 0 0;
					// font-size: 18px;

					span {
						// padding: 3px 8px;
						// background-color:#FFEA00;
						// color: #0075C2;
						background: linear-gradient(transparent 70%, #ffea00 0%);
						font-weight: bold;
						font-size: 26px;
					}
				}
			}
		}
	}

	.mapbox {
		iframe {
			vertical-align: bottom;
		}
	}
}

@media (min-width: 768px) {
	.venue_wrap {
		margin: 0 auto 0;
		padding: 50px 0 0;
		// background-image: url("../images/bg.svg");
		// background-repeat: repeat;

		.venue_inner {
			// margin: 0 auto;
			// max-width: 980px;
			width: 100%;

			.textbox {
				// padding: 0 0 50px;

				p {
					text-align: center;
					// line-height: 200%;

					&:nth-of-type(1) {
						padding: 8px 0 0;
						font-size: 22px;
						// font-weight: bold;
					}

					&:nth-of-type(2) {
					}

					&:nth-of-type(3) {
					}
				}
			}
		}
	}
}


/*================================================*/
/* 駐車場トップ */
/*================================================*/

.parking_wrap{
  margin-top: 20px;
  margin-bottom: 120px;
}

/*================================================*/
/* フッター */
/*================================================*/

footer {
	background-color: #0075c2;

	.inner {
		margin: 0 auto;
		padding: 30px 0;
		max-width: 980px;
		width: 100%;

		.textbox {
			margin: 0 auto;
			max-width: 514px;
			width: 80%;

			p {
				font-size: 18px;
				text-align: center;
				color: #fff;
				line-height: 200%;

				&:nth-of-type(1) {
					font-weight: bold;
				}

				&:nth-of-type(2) {
					padding-bottom: 24px;
				}

				&:nth-of-type(3) {
					font-size: 15px;
					text-align: left;
				}
			}
		}
	}
}

/* ページの上に戻るボタン */
.page_top {
	position: fixed;
	bottom: 3%;
	right: 20px;
}

.page_top a {
	color: #fff;
	text-align: center;

	img {
		max-width: 50px;
		width: 100%;
		height: auto;
	}

	&:hover {
		opacity: 0.7;
	}
}

@media (min-width: 768px) {
	.page_top a {
		// color: #fff;
		// text-align: center;

		img {
			max-width: 80px;
			// width: 100%;
			// height: auto;
		}

		// &:hover {
		//   opacity: 0.7;
		// }
	}
}

/*================================================*/
/* レスポンシブ 表示非表示 */
/*================================================*/
.hide-pc {
	display: block;
}

.hide-pctb {
	display: block;
}

.hide-tb {
	display: none;
}

.hide-tbsp {
	display: none;
}

.hide-sp {
	display: none;
}

.clearfix:after {
	display: block;
	clear: both;
	content: "";
}

/*タブレットのとき*/
@media screen and (max-width: 1024px) {
	.hide-pc {
		display: none;
	}

	.hide-pctb {
		display: block;
	}

	.hide-tb {
		display: block;
	}

	.hide-tbsp {
		display: block;
	}

	.hide-sp {
		display: none;
	}
}

/*スマホのとき*/
@media screen and (max-width: 769px) {
	.hide-pc {
		display: none;
	}

	.hide-pctb {
		display: none;
	}

	.hide-tb {
		display: none;
	}

	.hide-tbsp {
		display: block;
	}

	.hide-sp {
		display: block;
	}
}

/*================================================*/
/* レスポンシブ 並び順指定 */
/*================================================*/

@media screen and (max-width: 1024px) {
	.order1 {
		order: 1;
	}

	.order2 {
		order: 2;
	}

	.order3 {
		order: 3;
	}

	.order4 {
		order: 4;
	}

	.order5 {
		order: 5;
	}
}
